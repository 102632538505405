import React from 'react';
import Navbar from '../components/Navbar';
import Contact from '../components/Contact';

const ContactPage = () => {
  return (
    <>
      <Navbar />
      <Contact />
    </>
  );
};

export default ContactPage;
