export const SkillList = {
  acumen: [
    'devicon-html5-plain',
    'devicon-css3-plain',
    'devicon-javascript-plain',
    'devicon-typescript-plain',
    'devicon-react-original',
    'devicon-nextjs-original',
    'devicon-nodejs-plain',
    'devicon-express-original',
    'devicon-mongodb-plain',
    'devicon-firebase-plain',
    'devicon-sass-original',
    'devicon-less-plain-wordmark',
    'devicon-bootstrap-plain',
    'devicon-jquery-plain',
    'devicon-wordpress-plain',
    'devicon-git-plain',
  ],
  competencies: [
    'Mobile-first responsive design',
    'Object-oriented programming',
    'Cross-browser compatibility',
    'DOM Manipulation',
    'Testing and debugging',
    'Database management',
    'API design and integration',
    'Deployment',
  ],
  values: [
    'Attention to detail',
    'Creative and critical thinking',
    'Emotional intelligence',
    'Time management and organization',
    'Growth mindset and continuous learning',
    'Effective and open communication',
    'Cross-functional collaboration',
    'Open-mindedness and adaptability',
    'Servant leadership',
  ],
};
